<template>
	<a-layout>
		<a-layout-content>
			<div :class="{
				[$style.container]: true,
			}" :style="{ backgroundImage: `url(resources/images/content/photos/2.jpeg)` }">
				<div :class="$style.containerInner">
					<transition :name="settings.routerAnimation" mode="out-in">
						<router-view />
					</transition>
				</div>
				<div class="mt-auto pb-5 pt-5" style="color: #fff !important">
					<ul class="list-unstyled d-flex mb-0 flex-wrap justify-content-center" :class="[$style.footerNav]">
						<li class="list-inline-item">
							<a href="https://shieldmoto.mx/aviso-de-privacidad/" target="new" style="color: #fff !important">Aviso de Privacidad</a>
						</li>
					</ul>
					<div class="text-center">
						<p>STEEL BACKBONE | Todos los Derechos Reservados</p>
					</div>
				</div>
			</div>
		</a-layout-content>
	</a-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'AuthLayout',
	computed: mapState(['settings']),
	mounted() {
		this.bindMobileSlide()
		this.detectViewPort(true)
		window.addEventListener('resize', this.detectViewPortListener)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.detectViewPortListener)
	},
	methods: {
		toggleMobileMenu() {
			const value = !this.settings.isMobileMenuOpen
			this.$store.commit('CHANGE_SETTING', { setting: 'isMobileMenuOpen', value })
		},
		changeSetting: function (setting, value) {
			this.$store.commit('CHANGE_SETTING', { setting, value })
		},
		setViewPort: function (isMobileView = false, isTabletView = false) {
			this.$store.commit('CHANGE_SETTING', { setting: 'isMobileView', value: isMobileView })
			this.$store.commit('CHANGE_SETTING', { setting: 'isTabletView', value: isTabletView })
		},
		detectViewPortListener: function () {
			this.detectViewPort(false)
		},
		detectViewPort: function (firstLoad = false) {
			const isMobile = this.settings.isMobileView
			const isTablet = this.settings.isTabletView
			const width = window.innerWidth
			const state = {
				next: {
					mobile: width < 768,
					tablet: width < 992,
					desktop: !(width < 768) && !(width < 992),
				},
				prev: {
					mobile: isMobile,
					tablet: isTablet,
					desktop: !isMobile && !isTablet,
				},
			}
			// desktop
			if (state.next.desktop && (state.next.desktop !== state.prev.desktop || firstLoad)) {
				this.setViewPort(false, false)
			}
			// tablet & collapse menu
			if (state.next.tablet && !state.next.mobile && (state.next.tablet !== state.prev.tablet || firstLoad)) {
				this.setViewPort(false, true)
			}
			// mobile
			if (state.next.mobile && (state.next.mobile !== state.prev.mobile || firstLoad)) {
				this.setViewPort(true, false)
			}
		},
		bindMobileSlide() {
			// mobile menu touch slide opener
			const unify = (e) => {
				return e.changedTouches ? e.changedTouches[0] : e
			}
			document.addEventListener(
				'touchstart',
				(e) => {
					const x = unify(e).clientX
					this.touchStartPrev = x
					this.touchStartLocked = x > 70
				},
				{ passive: false },
			)
			document.addEventListener(
				'touchmove',
				(e) => {
					const x = unify(e).clientX
					const prev = this.touchStartPrev
					if (x - prev > 50 && !this.touchStartLocked) {
						this.toggleMobileMenu()
						this.touchStartLocked = true
					}
				},
				{ passive: false },
			)
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
