import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			redirect: '',
			component: AuthLayout,
			meta: {
				hidden: true,
			},
			children: [
				{
					path: '',
					meta: {
						title: 'Facturación',
					},
					component: () => import('./views/billing'),
				},
			],
		},

		// Redirect to 404
		{
			path: '*',
			redirect: '/',
			hidden: true,
		},
	],
})

export default router
